<template>
    <WindowLayout :title="title">
        <div class="row">
            <div class="col-sm-12">
                <div class="darmin card">
                    <div class="card-header pb-0 d-flex align-items-center">
                        <h5>{{ headerTitle }}</h5>
                    </div>
                    <div class="card-body theme-form">
                        <div class="row">
                            <div class="col">
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Nombre</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.name"
                                            :class="{ 'is-invalid': v.name.$error }"
                                        />
                                        <div class="invalid-feedback" v-if="v.name.$error">
                                            {{ v.name.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Apellidos</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.surnames"
                                            :class="{ 'is-invalid': v.surnames.$error }"
                                        />
                                        <div class="invalid-feedback" v-if="v.surnames.$error">
                                            {{ v.surnames.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label"
                                        >Tipo de Identificacion</label
                                    >
                                    <div class="col-sm-9">
                                        <select
                                            class="form-select btn-square digits"
                                            v-model="state.identification_type"
                                            :class="{ 'is-invalid': v.identification_type.$error }"
                                        >
                                            <option disable></option>
                                            <option value="CC">Cedula</option>
                                            <option value="CE">Cédula de extranjería</option>
                                            <option value="TI">Tarjeta de Identidad</option>
                                            <option value="RC">Registro civil de nacimiento</option>
                                            <option value="PP">Pasaporte</option>
                                        </select>
                                        <div
                                            class="invalid-feedback"
                                            v-if="v.identification_type.$error"
                                        >
                                            {{ v.identification_type.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label"># Identificacion</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.identification"
                                            :class="{ 'is-invalid': v.identification.$error }"
                                        />
                                        <div
                                            class="invalid-feedback"
                                            v-if="v.identification.$error"
                                        >
                                            {{ v.identification.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">F. Nacimiento</label>
                                    <div class="col-sm-9">
                                        <Datepicker
                                            inputClassName="form-control"
                                            v-model="state.born"
                                            :format="
                                                (date) => {
                                                    const day = `0${date.getDate()}`.slice(-2);
                                                    const month = `0${date.getMonth() + 1}`.slice(
                                                        -2
                                                    );
                                                    const year = date.getFullYear();

                                                    return `${day}/${month}/${year}`;
                                                }
                                            "
                                            :enableTimePicker="false"
                                            :class="{ 'is-invalid': v.born.$error }"
                                            autoApply
                                        />
                                        <div class="invalid-feedback" v-if="v.born.$error">
                                            {{ v.born.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Sexo</label>
                                    <div class="col-sm-9">
                                        <select
                                            class="form-select btn-square digits"
                                            v-model="state.sex"
                                            :class="{ 'is-invalid': v.sex.$error }"
                                        >
                                            <option disable></option>
                                            <option value="H">Masculino</option>
                                            <option value="M">Femenino</option>
                                        </select>
                                        <div class="invalid-feedback" v-if="v.sex.$error">
                                            {{ v.sex.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Estado civil</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.estado_civil"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Dirección</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.address"
                                            :class="{ 'is-invalid': v.address.$error }"
                                        />
                                        <div class="invalid-feedback" v-if="v.address.$error">
                                            {{ v.address.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Ocupación</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.ocupacion"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Email</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.email"
                                            :class="{ 'is-invalid': v.email.$error }"
                                        />
                                        <div class="invalid-feedback" v-if="v.email.$error">
                                            {{ v.email.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Contacto</label>
                                    <div class="col-sm-9">
                                        <input
                                            v-for="(contact, i) in state.contact"
                                            :key="i"
                                            class="form-control"
                                            type="text"
                                            v-model="state.contact[i]"
                                            :class="{ 'is-invalid': v.contact.$error }"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Entidad</label>
                                    <div class="col-sm-9">
                                        <InstitucionAutocomplete
                                            :class="{ 'is-invalid': v.eps.$error }"
                                            v-model="state.eps"
                                        />
                                        <div class="invalid-feedback" v-if="v.eps.$error">
                                            {{ v.eps.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Estado</label>
                                    <div class="col-sm-9">
                                        <SelectInputSimpleState
                                            v-model="state.status"
                                            :class="{ 'is-invalid': v.status.$error }"
                                        />
                                        <div class="invalid-feedback" v-if="v.status.$error">
                                            {{ v.status.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="darmin card" v-if="state.id">
                    <div class="card-header pb-0 d-flex"><h5>Historia</h5></div>
                    <div class="card-body">
                        <ConsultaList :pacienteId="state.id"></ConsultaList>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="darmin card header-card">
                    <div class="row">
                        <div class="col-12 text-end">
                            <div
                                class="btn btn-primary ms-3 mb-1"
                                @click="handleAction('save')"
                                v-if="state.id"
                            >
                                <i class="far fa-save"></i>
                                Guardar
                            </div>
                            <div
                                v-if="!state.id"
                                class="btn btn-primary ms-3 mb-1"
                                @click="handleAction('new')"
                            >
                                <i class="far fa-save"></i>
                                Crear
                            </div>
                            <div
                                v-if="!state.id"
                                class="btn btn-primary ms-3 mb-1"
                                @click="handleAction('new_view')"
                            >
                                <i class="far fa-save"></i>
                                Crear y mostrar
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <pre v-if="env === 'development'">{{ state }}</pre>
        <pre v-if="env === 'development'">{{ v }}</pre>
    </WindowLayout>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { reactive, onMounted, computed } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { useStore } from 'vuex';
// Components
import { useSnackbar } from 'vue3-snackbar';
import WindowLayout from '@/layouts/Window.layout.vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import SelectInputSimpleState from '@/components/form/SelectInputSimpleState.vue';
import ConsultaList from '@/modules/Care/Consulta/components/ConsultaList.vue';
import InstitucionAutocomplete from '@/modules/Care/Instituciones/components/InstitucionAutocomplete.vue';

// Services
import usePaciente from './services/usePaciente';

export default {
    name: 'PacienteForm',
    components: {
        WindowLayout,
        Datepicker,
        SelectInputSimpleState,
        ConsultaList,
        InstitucionAutocomplete,
    },
    props: {
        title: { type: String, default: 'Paciente' },
        headerTitle: { type: String, default: 'Ingresar Paciente' },
        item: Object,
    },
    setup(props) {
        const store = useStore();
        const snackbar = useSnackbar();
        const state = reactive({
            id: '',
            consecutive: '',
            name: '',
            surnames: '',
            identification_type: '',
            identification: '',
            born: '',
            sex: '',
            address: '',
            email: '',
            contact: [''],
            eps: '',
            ocupacion: '',
            referredBy: '',
            status: 1,
            estado_civil: '',
        });

        // eslint-disable-next-line object-curly-newline
        const { paciente, getPaciente, createPaciente, updatePaciente } = usePaciente();

        /** ***** Autocompleters ***** */

        /** ***** Helpers ***** */
        const setState = () => {
            state.id = paciente.value.id;
            state.consecutive = paciente.value.consecutive;
            state.name = paciente.value.name;
            state.surnames = paciente.value.surnames;
            state.identification_type = paciente.value.identification_type;
            state.identification = paciente.value.identification;
            state.born = paciente.value.born;
            state.sex = paciente.value.sex;
            state.address = paciente.value.address;
            state.email = paciente.value.email;
            state.contact = paciente.value.contact || [''];
            state.eps = paciente.value.eps?.id;
            state.ocupacion = paciente.value.ocupacion;
            state.status = paciente.value.status;
            state.estado_civil = paciente.value.estado_civil;

            // epsSelected.value = paciente.value.eps;
            // epsAutocomplete.value.setText(paciente.value.eps?.name || '');
        };

        const resetWindow = () => {
            state.id = '';
            state.consecutive = '';
            state.name = '';
            state.surnames = '';
            state.identification_type = '';
            state.identification = '';
            state.born = '';
            state.sex = '';
            state.address = '';
            state.email = '';
            state.contact = [''];
            state.eps = '';
            state.status = '';
            state.ocupacion = '';
            state.estado_civil = '';

            // epsSelected.value = null;
            // epsAutocomplete.value.setText('');
        };

        /** ***** Lifecycle Hooks ***** */
        onMounted(async () => {
            if (props.item) {
                await Promise.all([getPaciente(props.item.id)]);
                if (paciente.value) {
                    setState();
                }
            }
        });

        /** ***** Validator ***** */
        const rules = computed(() => ({
            name: { required },
            surnames: { required },
            identification_type: { required },
            identification: { required },
            born: { required },
            sex: { required },
            address: { required },
            email: { email },
            contact: { required },
            eps: { required },
            status: { required },
        }));
        const $v = useVuelidate(rules, state);

        /** ***** Actions ***** */
        const handleAction = async (action) => {
            await $v.value.$validate();
            if (!$v.value.$error) {
                // No errors
                let text;
                switch (action) {
                    case 'new':
                    case 'new_view':
                        await createPaciente({
                            name: state.name,
                            surnames: state.surnames,
                            identification: state.identification,
                            identification_type: state.identification_type,
                            born: state.born,
                            sex: state.sex,
                            address: state.address,
                            email: state.email,
                            contact: state.contact,
                            eps: state.eps,
                            status: state.status,
                            ocupacion: state.ocupacion,
                            estado_civil: state.estado_civil,
                        });
                        if (action === 'new_view') {
                            store.dispatch('ContentManager/openWindow', {
                                id: `PacienteForm_${paciente.value.id}`,
                                component: 'PacienteForm',
                                name: 'Paciente',
                                params: {
                                    title: 'Paciente',
                                    headerTitle: 'Editar Paciente',
                                    item: { ...paciente.value },
                                },
                            });
                        }
                        resetWindow();
                        text = 'Paciente ingresado';
                        break;
                    case 'save':
                        await updatePaciente(state.id, {
                            name: state.name,
                            surnames: state.surnames,
                            identification: state.identification,
                            identification_type: state.identification_type,
                            born: state.born,
                            sex: state.sex,
                            address: state.address,
                            email: state.email,
                            contact: state.contact,
                            eps: state.eps,
                            status: state.status,
                            ocupacion: state.ocupacion,
                            estado_civil: state.estado_civil,
                        });
                        text = 'Paciente actualizado';
                        break;
                    default:
                        break;
                }
                snackbar.add({
                    type: 'success',
                    text,
                });
                setState();
            } else {
                console.log($v.value.$errors);
                console.log($v.value);
                snackbar.add({
                    type: 'error',
                    text: 'Ups no podemos enviar la informacion sin algunos datos',
                });
            }
        };

        return {
            state,
            /** Autocompleters */
            /** Actions */
            handleAction,
            v: $v,
            env: process.env.NODE_ENV,
        };
    },
};
</script>

<style></style>
